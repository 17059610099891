import React, {useState, useEffect, Fragment, useContext, useMemo} from 'react';
import {Transition, Popover} from '@headlessui/react';
import {isEqual, orderBy} from 'lodash';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import ScannerContext from '../../../Context/ScannerContext';

const searchRankPriority = {
  EXACTMATCH: 100000,
  PARTIALMATCH: 10000,
  INCLUDES: 1000,
  MISSING: 0,
};

const MultiSelectWithSearch = ({group, multiScreen, handleUpdateMultiSelect, currentDate}) => {
  //   const {group, multiScreen, handleUpdateMultiSelect} = useContext(ScannerContext);
  if (!group?.searchValue?.filters.length) return null;
  const [filter, setGroupFilters] = useState(null);
  const [label, setLabel] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [allValues, setAllValues] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (group?.searchValue?.filters?.length) {
      const filtFind = group?.searchValue?.filters?.find(
        (filt) => filt && filt?.filterable?.type === 'multiselect' && filt?.type === 'string',
      );
      setGroupFilters(filtFind);
      setLabel(filtFind?.title);
    }
  }, [group?.searchValue?.filters, currentDate]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const clearAll = () => {
    handleUpdateMultiSelect(null, filter?.key, null, true);
    setSelected([]);
  };

  const hasNumbers = (t) => {
    const regex = /\d/g;
    return regex.test(t);
  };

  useEffect(() => {
    const values = filter?.filterable?.options;
    if (isEqual(values, allValues)) return;
    setAllValues(values);
  }, [filter, filter?.filterable?.options, currentDate]);

  useEffect(() => {
    if (allValues?.length) {
      if (!searchValue) {
        const formattedForOrderBy = allValues.map((o) => {
          const itemValue = o?.key ?? o;
          const tickerSymbol = hasNumbers(itemValue) && itemValue ? itemValue.split(/[0-9]/)[0] : itemValue;
          return {
            ticker: tickerSymbol,
            value: itemValue,
          };
        });
        const arrayUniqueByKey = [...new Map(formattedForOrderBy.map((item) => [item.ticker, item])).values()];
        const sorted = orderBy(arrayUniqueByKey, ['ticker'], ['asc']);
        setCheckboxValues(sorted);
        return;
      }
      const filtered = allValues?.filter((item) => {
        const itemValue = item?.key ?? item;
        return itemValue.toLowerCase().includes(searchValue.toLowerCase());
      });

      if (filtered?.length) {
        const formattedForOrderBy = filtered.map((item) => {
          const itemValue = item?.key ?? item;
          if (searchValue?.length) {
            const tickerSymbol = hasNumbers(itemValue) && itemValue ? itemValue.split(/[0-9]/)[0] : itemValue;
            const splitValue = itemValue.split(searchValue)?.filter((t) => t?.length);
            let remainingSymbol = '';
            if (splitValue?.length) {
              remainingSymbol = splitValue[splitValue?.length - 1];
              if (remainingSymbol?.length > 1) {
                remainingSymbol = remainingSymbol?.slice(0, 1);
              }
            }
            if (tickerSymbol?.toLowerCase() === searchValue?.toLowerCase()) {
              return {
                rank: searchRankPriority.EXACTMATCH,
                ticker: tickerSymbol,
                remaining: remainingSymbol,
                value: itemValue,
              };
            }
            if (tickerSymbol?.slice(0, searchValue?.length)?.toLowerCase() === searchValue?.toLowerCase()) {
              return {
                rank: searchRankPriority.PARTIALMATCH,
                ticker: tickerSymbol,
                remaining: remainingSymbol,
                value: itemValue,
              };
            }
            return {
              rank: searchRankPriority.INCLUDES,
              ticker: tickerSymbol,
              remaining: remainingSymbol,
              value: itemValue,
            };
          }
          const tickerSymbol = hasNumbers(itemValue) && itemValue ? itemValue.split(/[0-9]/)[0] : itemValue;
          return {
            rank: searchRankPriority.MISSING,
            ticker: tickerSymbol,
            remaining: '',
            value: itemValue,
          };
        });
        const arrayUniqueByKey = [...new Map(formattedForOrderBy.map((item) => [item.ticker, item])).values()];
        const sorted = orderBy(arrayUniqueByKey, ['rank', 'remaining', 'ticker'], ['desc', 'asc', 'asc']);

        setCheckboxValues(sorted);
        return;
      }

      setCheckboxValues([]);
    }
  }, [searchValue, allValues]);

  const memoizedCheckboxValues = useMemo(() => checkboxValues, [checkboxValues]);
  const memoizedFilter = useMemo(() => filter, [filter?.value?.length]);

  const updateFilters = (e, filt, field) => {
    handleUpdateMultiSelect(e.target.checked, filt?.key, field.ticker);
    setSelected((prev) => (e.target.checked ? [...prev, field.ticker] : prev.filter((item) => item !== field.ticker)));
  };

  return (
    <div className="flex flex-col grow w-1/6 ">
      <div className="flex items-center justify-start ">
        <Popover as="div" className="relative inline-block text-left">
          <div>
            <Popover.Button
              style={{background: 'rgba(148, 196, 242, 0.1)'}}
              className={`${
                multiScreen ? '' : 'px-4 py-1.5 '
              } text-[12px] text-[#70769B] whitespace-nowrap filter-label items-center px-4 py-1.5 inline-flex justify-between w-full min-w-[150px] rounded-[12px] border-none  border-gray-300 shadow-sm bg-white text-md font-medium  hover:bg-gray-50 focus:outline-none focus:ring-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-transparent`}
            >
              {label}
              <ChevronDownIcon className="w-4 h-4 text-gray-500 ml-2" aria-hidden="true" />
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="w-auto origin-top-left absolute left-0 mt-2 rounded-md shadow-lg bg-white z-50 ring-1 ring-black ring-opacity-5 focus:outline-none multiselect-search-wrap rounded-t-[10px] ">
              <div className="multi-select-search border-0">
                <input
                  type="text"
                  onChange={handleSearch}
                  placeholder="Search for Symbol"
                  className="border-0 border-b border-gray-300 block  p-1.5 text-gray-900 placeholder-gray-500 focus:ring-0 rounded-t-[10px] search-input"
                />
              </div>
              <div className="divide-y divide-gray-200 list-dropdown">
                <fieldset className="px-4 py-2 gap-0.5 flex flex-col h-56 overflow-y-scroll">
                  {memoizedCheckboxValues?.map((field) => (
                    <div className="relative flex items-center py-0.5" key={field?.ticker ?? field}>
                      <div className="min-w-0 flex-1 text-md">
                        <label
                          htmlFor={field?.ticker ?? field}
                          className="font-medium text-gray-700 checkbox-label cursor-pointer"
                        >
                          {field.ticker}
                        </label>
                      </div>
                      <div className="ml-3 flex items-center h-5 cursor-pointer">
                        <input
                          onChange={(e) => updateFilters(e, filter, field)}
                          checked={selected.includes(field.ticker)}
                          id={field?.ticker ?? field}
                          aria-describedby={field?.ticker ?? field}
                          name={field?.ticker ?? field}
                          type="checkbox"
                          className="focus:ring-transparent h-4 w-4 text-sky-500 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  ))}
                </fieldset>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        {filter?.value?.length ? (
          <button type="button" className="btn focus:ring-transparent clear-all ml-2" onClick={clearAll}>
            Clear All
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(MultiSelectWithSearch, (prev, next) => {
  const prevFiltFind = prev?.group?.searchValue?.filters?.find(
    (filt) => filt && filt?.filterable?.type === 'multiselect' && filt?.type === 'string',
  );
  const nextFiltFind = next?.group?.searchValue?.filters?.find(
    (filt) => filt && filt?.filterable?.type === 'multiselect' && filt?.type === 'string',
  );
  const optionsSame =
    isEqual(prevFiltFind?.filterable?.options, nextFiltFind?.filterable?.options) &&
    isEqual(prevFiltFind?.value, nextFiltFind?.value);
  const currentDateSame = prev?.currentDate === next?.currentDate;
  return optionsSame && currentDateSame;
});

// export default MultiSelectWithSearch;
