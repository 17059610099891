import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import {CSVLink, CSVDownload} from 'react-csv';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ScannerTableHeader from './ScannerTableHeader';
import {getComparator, stableSort} from '../../Helpers/tableHelpers';
import ScannerItem from './ScannerItem';

const ScannerTable = ({
  tableData,
  page,
  dataTypes,
  orderBy,
  group,
  multiScreen,
  order,
  rowsPerPage,
  dispatch,
  handleChangePage,
  getSymbolData,
  openOptionsModal,
  showReportModal,
  openReportModal,
  optionsColumns,
  optionsAllowed,
  openLockedModal,
  alignColumns,
  allowExportCSV,
  trimNumbersFromSymbol,
  hasReportPopout,
  symbolsWithReports,
}) => {
  const [currentData, setCurrentData] = useState(tableData);
  const [filterType, setFilterType] = useState(dataTypes?.find((type) => type.key === orderBy) ?? {});

  useEffect(() => {
    setFilterType(dataTypes?.find((type) => type.key === orderBy) ?? {});
  }, [orderBy])

  useEffect(() => {
    const keys = Object.keys(tableData);
    const toArray = keys.reduce((acc, key) => [...(acc || []), tableData[key]], []);
    setCurrentData(toArray);
  }, [tableData, group.data, group.filteredData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    dispatch({
      type: 'SET_ORDERBY',
      payload: {group, orderBy: property, order: isAsc ? 'desc' : 'asc'},
    });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: 'SET_ROWS_PER_PAGE',
      payload: {rowsPerPage: parseInt(event.target.value, 10), group},
    });
  };

  const toCSVData = (data) => {
    if (!data || !Array.isArray(data)) return [];

    const csv = [];
    csv.push(dataTypes.map((type) => type.title));

    data.forEach((d) => {
      csv.push(
        dataTypes.map((type) => {
          const {key} = type;
          return d[key]?.value;
        }),
      );
    });

    return csv;
  };

  // Avoid a layout jump when reaching the last page with empty tableData.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - group?.data?.length) : 0;

  return (
    <Box sx={{width: 'auto', mx: 'auto'}}>
      <Paper sx={{width: 'auto', mb: 2, maxHeight: '80vh', overflowY: 'auto'}}>
        <TableContainer >
          <Table stickyHeader sx={multiScreen ? {} : {minWidth: 0}} aria-labelledby="tableTitle" size="small">
            {dataTypes.length > 0 && (
              <ScannerTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                tableHeaders={dataTypes}
                multiScreen={multiScreen}
                alignColumns={alignColumns}
              />
            )}
            {currentData && currentData.length ? (
              <TableBody>
                {stableSort(currentData, order, orderBy, filterType, [], group.searchValue)
                  .filter((item) => item !== null && item && Object.keys(item ?? {}).length && item?.Symbol?.value)
                  .map((row, index) => (
                    <ScannerItem
                      row={row}
                      key={`${row?.Symbol}-${index * 3}`}
                      dataTypes={dataTypes}
                      type={group?.type}
                      group={group}
                      getSymbolData={getSymbolData}
                      openOptionsModal={openOptionsModal}
                      showReportModal={showReportModal}
                      optionsColumns={optionsColumns}
                      optionsAllowed={optionsAllowed}
                      openLockedModal={openLockedModal}
                      alignColumns={alignColumns}
                      trimNumbersFromSymbol={trimNumbersFromSymbol}
                      hasReportPopout={hasReportPopout}
                      openReportModal={openReportModal}
                      symbolsWithReports={symbolsWithReports}
                    />
                  ))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow rowSpan={10} colSpan={10}>
                  <TableCell align="center" rowSpan={10} colSpan={10}>
                    <h1 className="no-results text-[#b2b6ce] text-3xl mt-4">
                      {!Object.keys(group?.filteredData ?? {})?.length && !group?.data?.length
                        ? 'No Data Available'
                        : 'No Results Found'}
                    </h1>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={Object.keys(group?.filteredData || {})?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={group?.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
        <CSVLink className="download-csv-link" data={toCSVData(currentData)}>
          <span className="text-menu-item-text hover:bg-menu-item-active hover:text-menu-item-text-active px-2 py-4 text-sm active">
            Download CSV
          </span>
        </CSVLink>
      </Paper>
    </Box>
  );
};

export default React.memo(ScannerTable, (prev, next) => {
  const dataSame = JSON.stringify(prev?.group?.filteredData) === JSON.stringify(next?.group?.filteredData);
  const orderBySame = prev?.group?.orderBy === next?.group?.orderBy;
  const orderSame = prev?.group?.order === next?.group?.order;
  const pageSame = prev?.group?.page === next?.group?.page;
  const rowsPerPageSame = prev?.group?.rowsPerPage === next?.group?.rowsPerPage;
  const dateSame = prev?.group?.date === next?.group?.date;
  const selectedDateSame = prev?.group?.selectedDate === next?.group?.selectedDate;
  const symbolsWithReportsSame = JSON.stringify(prev?.symbolsWithReports) === JSON.stringify(next?.symbolsWithReports);
  return dataSame && orderBySame && orderSame && pageSame && rowsPerPageSame && dateSame && selectedDateSame && symbolsWithReportsSame;
});
